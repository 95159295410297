import axios from "axios";
import { apiClient } from "../apiClient";
import { base64ToBlob } from "../base64ToBlob";

export default {
  authExamLink: async (link = "") => {
    let request = {
      method: "get",
      url: `/Live/exambylink?link=${link}`,
    };
    return await axios.get(process.env.REACT_APP_BASE_URL + request.url)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        console.error(err);
      });
  },
  getCandidateName: async (link = "") => {
    let request = {
      method: "get",
      url: `/Live/candidate?link=${link}`,
    };

    return await apiClient(request)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.error(err);
      });
  },
  saveCandidateName: async (link = "", name) => {
    let request = {
      method: "post",
      url: `/Live/candidate-name?name=${name}&link=${link}`,
    };

    return await apiClient(request)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.error(err);
      });
  },
  examFormDetails: async (link = "") => {
    let request = {
      method: "get",
      url: `/Live/exam-formdetails?link=${link}`,
    };
    return await apiClient(request)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        console.error(err);
      });
  },
  validateImages: async (candidateIdImage, candidateImage) => {
    // console.log(candidateIdImage, candidateImage);

    let formData = new FormData();
    if (candidateIdImage) {
      const idCardImageBlob = base64ToBlob(candidateIdImage);
      formData.append(`IdCardImage`, idCardImageBlob);

    }
    if (candidateImage) {
      const candidateImageBlob = base64ToBlob(candidateImage);
      formData.append(`CandidateImage`, candidateImageBlob);
      localStorage.setItem("candidateImage", candidateImage)

    }
    if (candidateIdImage) {
      let request = {
        method: "POST",
        url: `/Live/verify-candidate`,
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data'  // Set content type for multipart
        }
      };
      return await apiClient(request)
        .then((res) => {

          return res.data;
        })
        .catch((err) => {
          console.error(err);
        });
    }
    else {
      return {
        data: true,
      }
    }
  },
  startExam: async (data) => {
    let request = {
      method: "POST",
      url: `/Live/start-exam`,
      data,
    };
    return apiClient(request)
      .then((res) => {
        if (res.data) {

          return res.data;
        } else {
          return null;
        }
      })
      .catch((err) => console.log(err.message));
  },
  getQuestion: async (oderId) => {
    let request = {
      method: "get",
      url: `/Live/get-question?OrderId=${oderId}`,
    };
    return apiClient(request)
      .then((res) => {
        return res.data.data;
      })
      .catch((err) => console.log(err.message));
  },
  submitExam: async (Duration) => {
    let request = {
      method: "post",
      url: `/Live/submit-exam?Duration=${Duration}`,
    };

    return apiClient(request)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return {
          data: false,
          message: err.message
        }
      });
  },
  saveExamAnswer: async (data) => {
    let request = {
      method: "post",
      url: `/Live/save-answer`,
      data,
    };

    return apiClient(request)
      .then((res) => {
        return res.data.data;
      })
      .catch((err) => {
        console.log(err.message);
      });
  },
  saveLastDuration: async (data) => {
    let request = {
      method: "post",
      url: `/Live/update-duration?duration=${data}`,
    };
    return apiClient(request)
      .then((res) => {
        return res.data.data;
      })
      .catch((err) => {
        console.log(err.message);
      });
  },
  submitFeedBack: async (data) => {
    let request = {
      method: "post",
      url: `/Live/submit-feedback`,
      data,
    };
    return apiClient(request)
      .then((res) => {
        return res.data.data;
      })
      .catch((err) => console.log(err.message));
  },
  tagAndUntag: (OrderId) => {
    let request = {
      method: "post",
      url: `/Live/tag-untag`,
      params: {
        OrderId,
      },
    };
    return apiClient(request)
      .then((res) => {
        console.log(res.data.data);
        return res.data.data;
      })
      .catch((err) => console.log(err.message));
  },
  eraseAnswer: (OrderId) => {
    let request = {
      method: "post",
      url: `/Live/erase-answer`,
      params: {
        OrderId,
      },
    };
    return apiClient(request)
      .then((res) => {
        console.log(res.data.data);
        return res.data.data;
      })
      .catch((err) => console.log(err.message));
  },
  saveCapturedImages: async (data = "") => {
    let request = {
      method: "POST",
      url: `/Live/saveCapturedImage?CapturedImage=${data}`,
    };
    return apiClient(request)
      .then((res) => {
        if (res.data) {
          return res.data.data;
        } else {
          return null;
        }
      })
      .catch((err) => console.log(err.message));
  },
  registerUser: async (data) => {
    let request = {
      method: "post",
      url: `/Live/submit-exam-formdetails`,
      data,
    };
    return apiClient(request)
      .then((res) => {
        if (res.data.data) {
        }
      })
      .catch((err) => console.log(err.message));
  },
  logoutUser: async (data = {}) => {
    let request = {
      method: "post",
      url: `/Live/logout`,
      data,
    };
    return apiClient(request).then(res => {
      localStorage.clear()
      sessionStorage.clear()
    });
  },

  saveActivityCaptured: async (data) => {
    let request = {
      method: "post",
      url: `/Live/exam-activity`,
      data,
    };
    return apiClient(request);
  },
  getIP: async () => {
    const response = await fetch("https://api.ipify.org/?format=json");
    const data = await response.json();
    return data;
  },
  browserDetect: async () => {
    var nAgt = navigator.userAgent;
    var browserName = navigator.appName;
    var verOffset;
    // Opera 8.0+
    if ((verOffset = nAgt.indexOf("Opera")) != -1) {
      browserName = "Opera";
    } else if ((verOffset = nAgt.indexOf("MSIE")) != -1) {
      browserName = "Microsoft Internet Explorer";
    } else if ((verOffset = nAgt.indexOf("Chrome")) != -1) {
      browserName = "Chrome";
    } else if ((verOffset = nAgt.indexOf("Safari")) != -1) {
      browserName = "Safari";
    } else if ((verOffset = nAgt.indexOf("Firefox")) != -1) {
      browserName = "Firefox";
    }
    return browserName;
  },
  detectOperatingSystem: async () => {
    var Name = "Not known";
    if (navigator.appVersion.indexOf("Win") != -1) Name = "Windows OS";
    if (navigator.appVersion.indexOf("Mac") != -1) Name = "MacOS";
    if (navigator.appVersion.indexOf("X11") != -1) Name = "UNIX OS";
    if (navigator.appVersion.indexOf("Linux") != -1) Name = "Linux OS";
    return Name;
  },
  // getBase64Image: async (url) => {
  //     var canvas = document.createElement("canvas");
  //     var img = document.createElement("img");
  //     img.setAttribute('src', url);
  //     canvas.width = img.width;
  //     canvas.height = img.height;
  //     var ctx = canvas.getContext("2d");
  //     ctx.drawImage(img, 0, 0);
  //     var dataURL = canvas.toDataURL("image/png");
  //     return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
  // }
};
