import React, { Suspense, useEffect } from "react";
import { BrowserRouter as Router, useHistory } from "react-router-dom";
import { PageLoader } from "shared/Loader";
import { LiveAuthProvider } from "app/Context/ExamAuth/LiveAuthProvider";
import LiveExamRouter from "app/LiveExamRouter";
import history from "history.js";
import { Provider } from "react-redux";
import Store from "./redux/Store";
import { ToastContainer, Bounce, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.ctrlKey || event.altKey || event.metaKey) {
        event.preventDefault();
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <Provider store={Store}>
      <Router history={history}>
        <Suspense fallback={<PageLoader />}>
          <LiveAuthProvider>
            <LiveExamRouter />
            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick={false}
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="colored"
              transition={Slide}
            />
          </LiveAuthProvider>
        </Suspense>
      </Router>
    </Provider>
  );
}

export default App;
